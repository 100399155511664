@charset "utf-8";
/* CSS Document */

/******************************

[Table of Contents]

1. Fonts
2. Body and some general stuff
3. Header
	3.1 Top Bar
	3.2 Header Content
	3.3 Logo
	3.4 Main Nav
	3.5 Hamburger
4. Menu
5. Section
6. Home
7. Features
8. Courses
9. Counter
10. Events
11. Team
12. News
13. Newsletter
14. Footer



******************************/

/***********
1. Fonts
***********/

@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:400,700|Roboto:300,400,500,700,900');

/*********************************
2. Body and some general stuff
*********************************/

*
{
	margin: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}
body
{
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
	font-weight: 400;
	background: #FFFFFF;
	color: #a5a5a5;
}
div
{
	display: block;
	position: relative;
	-webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
ul
{
	list-style: none;
	margin-bottom: 0px;
}
p
{
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
	line-height: 1.85;
	font-weight: 400;
	color: #76777a;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}
p a
{
	display: inline;
	position: relative;
	color: inherit;
	border-bottom: solid 1px #ffa07f;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
p:last-of-type
{
	margin-bottom: 0;
}
a, a:hover, a:visited, a:active, a:link
{
	text-decoration: none;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}
p a:active
{
	position: relative;
	color: #FF6347;
}
p a:hover
{
	color: #FFFFFF;
	background: #ffa07f;
}
p a:hover::after
{
	opacity: 0.2;
}
::selection
{
	
}
p::selection
{
	
}
h1{font-size: 48px;}
h2{font-size: 36px;}
h3{font-size: 24px;}
h4{font-size: 18px;}
h5{font-size: 14px;}
h1, h2, h3, h4, h5, h6
{
	font-family: 'Roboto Slab', serif;
	font-weight: 700;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
	color: #384158;
	margin-bottom: 0;
}
h1::selection, 
h2::selection, 
h3::selection, 
h4::selection, 
h5::selection, 
h6::selection
{
	
}
.form-control
{
	color: #db5246;
}
section
{
	display: block;
	position: relative;
	box-sizing: border-box;
}
.clear
{
	clear: both;
}
.clearfix::before, .clearfix::after
{
	content: "";
	display: table;
}
.clearfix::after
{
	clear: both;
}
.clearfix
{
	zoom: 1;
}
.float_left
{
	float: left;
}
.float_right
{
	float: right;
}
.trans_200
{
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.trans_300
{
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}
.trans_400
{
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 400ms ease;
}
.trans_500
{
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}
.fill_height
{
	height: 100%;
}
.super_container
{
	width: 100%;
	overflow: hidden;
}
.prlx_parent
{
	overflow: hidden;
}
.prlx
{
	height: 130% !important;
}
.parallax-window
{
    min-height: 400px;
    background: transparent;
}
.nopadding
{
	padding: 0px !important;
}
/*********************************
3. Header
*********************************/

.header
{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 100;
	box-shadow: 0px 5px 20px rgba(0,0,0,0.05);
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.header.scrolled
{
	top: -40px;
}

/*********************************
3.1 Top Bar
*********************************/

.top_bar
{
	width: 100%;
	background: #14bdee;
}
.header.scrolled .top_bar
{

}
.top_bar_container
{
	width: 100%;
	height: 100%;
}
.top_bar_content
{
	width: 100%;
	height: 40px;
}
.top_bar_contact_list li
{
	display: inline-block;
}
.question
{
	font-family: 'Roboto Slab', serif;
	font-size: 14px;
	color: #FFFFFF;
	margin-left: 0px;
}
.top_bar_contact_list li i,
.top_bar_contact_list li > div
{
	display: inline-block;
	font-size: 14px;
	color: #FFFFFF;
}
.top_bar_contact_list li > div
{
	margin-left: 1px;
}
.top_bar_contact_list li:not(:last-child)
{
	margin-right: 21px;
}
.top_bar_login
{
	height: 100%;
}
.login_button
{
	width: 160px;
	height: 100%;
	background: #f3f3f3;
	text-align: center;
}
.login_button a
{
	display: block;
	font-size: 14px;
	font-weight: 500;
	line-height: 40px;
	color: #384158;
}

/*********************************
3.2 Header Content
*********************************/

.header_container
{
	width: 100%;
	background: #FFFFFF;
}
.header_content
{
	height: 90px;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.header.scrolled .header_content
{
	height: 80px;
}

/*********************************
3.3 Logo
*********************************/

.logo,
.logo_text
{
	display: inline-block;
	white-space: normal;
	
}
.logo
{
	width: 60px;
	height: 60px;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.logo img
{
	max-width: 100%;
}
.logo_text
{
	font-family: 'Roboto Slab', serif;
	font-size: 36px;
	font-weight: 700;
	line-height: 0;
	color: #384158;
	vertical-align: middle;
	margin-left: 7px;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.logo_text span
{
	color: #14bdee;
}
.header.scrolled .logo
{
	width: 40px;
	height: 40px;
}
.header.scrolled .logo_text
{
	font-size: 24px;
}

/*********************************
3.4 Main Nav
*********************************/

.main_nav_contaner
{
	font-size: 18px;
	font-weight: 500;
	color: #384158;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.active::after {
	color: #14bdee;
}
.main_nav,
.search_button,
.shopping_cart
{
	display: inline-block;
}
.main_nav li
{
	display: inline-block;
	position: relative;
}
.main_nav li:not(:last-child)
{
	margin-right: 44px;
}
.main_nav li a
{
	font-size: 18px;
	font-weight: 500;
	color: #384158;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.main_nav li a:hover,
.main_nav li.active a
{
	color: #14bdee;
}
.main_nav li.active::after
{
	display: block;
	position: absolute;
	bottom: -2px;
	left: 0;
	width: 100%;
	height: 2px;
	background: #14bdee;
	content: '';
}
.search_button
{
	margin-left: 46px;
	cursor: pointer;
}
.shopping_cart
{
	margin-left: 23px;
	cursor: pointer;
}
.search_button i,
.shopping_cart i
{
	font-size: 18px;
	color: #181818;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.search_button:hover i,
.shopping_cart:hover i
{
	color: #14bdee;
}
.header_search_form
{
	display: block;
	position: relative;
	width: 40%;
}
.header_search_container
{
	position: absolute;
	bottom: 0px;
	left: 0px;
	width: 100%;
	background: #14bdee;
	z-index: -1;
	opacity: 0;
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 400ms ease;
}
.header_search_container.active
{
	bottom: -73px;
	opacity: 1;
}
.header_search_content
{
	width: 100%;
	height: 73px;
}
.search_input
{
	width: 100%;
	height: 40px;
	border: none;
	outline: none;
	padding-left: 20px;
}
.header_search_button
{
	position: absolute;
	top: 0;
	right: 0;
	width: 40px;
	height: 100%;
	border: none;
	outline: none;
	cursor: pointer;
}

/*********************************
3.5 Hamburger
*********************************/

.hamburger_container
{

}
.hamburger
{
	display: none;
	cursor: pointer;
}
.hamburger i
{
	font-size: 20px;
	color: #353535;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.hamburger:hover i
{
	color: #14bdee;
}

/*********************************
4. Menu
*********************************/

.menu
{
	position: fixed;
	top: 0;
	right: -400px;
	width: 400px;
	height: 100vh;
	background: #FFFFFF;
	z-index: 101;
	padding-right: 60px;
	padding-top: 87px;
	padding-left: 50px;
}
.menu .logo a
{
	color: #000000;
}
.menu.active
{
	right: 0;
}
.menu_close_container
{
	position: absolute;
	top: 30px;
	right: 60px;
	width: 18px;
	height: 18px;
	transform-origin: center center;
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
	cursor: pointer;
}
.menu_close
{
	width: 100%;
	height: 100%;
	transform-style: preserve-3D;
}
.menu_close div
{
	width: 100%;
	height: 2px;
	background: #232323;
	top: 8px;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.menu_close div:last-of-type
{
	-webkit-transform: rotate(90deg) translateX(-2px);
	-moz-transform: rotate(90deg) translateX(-2px);
	-ms-transform: rotate(90deg) translateX(-2px);
	-o-transform: rotate(90deg) translateX(-2px);
	transform: rotate(90deg) translateX(-2px);
	transform-origin: center;
}
.menu_close:hover div
{
	background: #937c6f;
}
.menu .logo
{
	margin-bottom: 60px;
}
.menu_nav ul li
{
	margin-bottom: 9px;
}
.menu_nav ul li a
{
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
	text-transform: uppercase;
	color: rgba(0,0,0,1);
	font-weight: 700;
	letter-spacing: 0.1em;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.menu_nav ul li a:hover
{
	color: #14bdee;
}
.menu .search
{
	width: 100%;
	margin-bottom: 67px;
}
.search
{
	display: inline-block;
	width: 400px;
	-webkit-transform: translateY(2px);
	-moz-transform: translateY(2px);
	-ms-transform: translateY(2px);
	-o-transform: translateY(2px);
	transform: translateY(2px);
}
.menu .header_search_form
{
	width: 100%;
}
.search form
{
	position: relative;
}
.menu .search_input
{
	width: 100%;
	height: 40px;
	background: rgba(0,0,0,0.1);
	border-radius: 3px;
	border: none;
	outline: none;
	padding-left: 15px;
	color: rgba(0,0,0,0.5);
}
.menu .search_input::-webkit-input-placeholder
{
	font-family: 'Roboto', sans-serif;
	font-size: 14px !important;
	font-weight: 400 !important;
	color: rgba(0,0,0,0.4) !important;
}
.menu .search_input:-moz-placeholder
{
	font-family: 'Roboto', sans-serif;
	font-size: 14px !important;
	font-weight: 400 !important;
	color: rgba(0,0,0,0.4) !important;
}
.menu .search_input::-moz-placeholder
{
	font-family: 'Roboto', sans-serif;
	font-size: 14px !important;
	font-weight: 400 !important;
	color: rgba(0,0,0,0.4) !important;
} 
.menu .search_input:-ms-input-placeholder
{ 
	font-family: 'Roboto', sans-serif;
	font-size: 14px !important;
	font-weight: 400 !important;
	color: rgba(0,0,0,0.4) !important;
}
.menu .search_input::input-placeholder
{
	font-family: 'Roboto', sans-serif;
	font-size: 14px !important;
	font-weight: 400 !important;
	color: rgba(0,0,0,0.4) !important;
}

/*********************************
5. Section
*********************************/

.section_title_container
{
	max-width: 600px;
	margin: 0 auto;
}
.section_title
{
	line-height: 1.2;
}
.section_subtitle
{
	line-height: 1.85;
	margin-top: 14px;
}
.section_background
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.card-header {
	background-color: #14bdee;
}
/*********************************
6. Home
*********************************/

.home
{
	width: 100%;
	height: 850px;
}
.home_slider_container
{
	width: 100%;
	height: 100%;
}
.home_slider
{
	width: 100%;
	height: 100%;
}
.home_slider_background
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
}
.home_slider_content
{
	position: absolute;
	top: 80%;
	left: 0;
	width: 100%;
	height: auto;
	background-color: rgba(0, 0, 0, 0.5);
}
.home_slider_title
{
	font-family: 'Roboto Slab', serif;
	font-size: 60px;
	font-weight: 700;
	line-height: 1.2;
	color: #ffffff;
}
.home_slider_subtitle
{
	font-size: 24px;
	color: #ffffff;
	margin-top: 9px;
}
.home_slider_nav
{
	position: absolute;
	top: 55.8%;
	width: 50px;
	background: #b6b7bd;
	border-radius: 50%;
	text-align: center;
	z-index: 10;
	cursor: pointer;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.home_slider_prev
{
	left: 75px;
}
.home_slider_next
{
	right: 75px;
}
.home_slider_nav i
{
	line-height: 50px;
	color: #FFFFFF;
	font-size: 22px;
}
.home_slider_nav:hover
{
	background: #14bdee;
}

/*********************************
7. Features
*********************************/
.team
{
	width: 100%;
	/* background: #14bfee1f; */
	padding-top: 93px;
	padding-bottom: 103px;
}
.features
{
	width: 100%;
	background: #049fa41f;
	padding-top: 93px;
	padding-bottom: 103px;
}
.features_row
{
	margin-top: 55px;
}
.features2
{
	width: 100%;
	background: #05d7f3;
	padding-top: 93px;
	padding-bottom: 103px;
}
.feature
{
	width: 100%;
	padding-top: 30px;
	padding-bottom: 28px;
	padding-left: 15px;
	padding-right: 15px;
	background: #FFFFFF;
	border-radius: 6px;
}
.feature:hover
{
	box-shadow: 0px 5px 40px rgba(29,34,47,0.15);
}
.feature_icon
{
	height: 70px;
}
.feature_icon img
{
	max-width: 100%;
	border-radius: 6px;
	margin-bottom: 10%;
}
.feature_title
{
	position: relative;
	font-size: 20px;
	margin-top: 100px;
}

/*********************************
8. Courses
*********************************/

.courses
{
	width: 100%;
	padding-top: 93px;
	padding-bottom: 100px;
}
.courses_row
{
	margin-top: 45px;
}
.course
{
	width: 100%;
	border-radius: 6px;
	background: #FFFFFF;
	box-shadow: 0px 1px 10px rgba(29,34,47,0.1);
}
.course_image
{
	width: 100%;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	overflow: hidden;
}
.course_image img
{
	max-width: 100%;
}
.course_body
{
	padding-top: 22px;
	padding-left: 30px;
	padding-right: 30px;
	padding-bottom: 23px;
}
.course_title a
{
	font-family: 'Roboto Slab', serif;
	font-size: 20px;
	color: #384158;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.course_title a:hover
{
	color: #14bdee;
}
.course_teacher
{
	font-size: 15px;
	font-weight: 400;
	color: #384158;
	margin-top: 6px;
}
.course_text
{
	margin-top: 13px;
}
.course_footer
{
	padding-left: 30px;
	padding-right: 30px;
}
.course_footer_content
{
	width: 100%;
	border-top: solid 1px #e5e5e5;
	padding-top: 9px;
	padding-bottom: 11px;
}
.course_info
{
	display: inline-block;
	font-size: 14px;
	font-weight: 400;
	color: #55555a;
}
.course_info:first-child
{
	margin-right: 18px;
}
.course_info i
{
	color: #ffc80a;
}
.course_price
{
	font-family: 'Roboto Slab', serif;
	font-size: 20px;
	font-weight: 700;
	color: #14bdee;
}
.course_price span
{
	font-family: 'Roboto Slab', serif;
	font-size: 14px;
	font-weight: 400;
	text-decoration: line-through;
	color: #b5b8be;
	margin-right: 10px;
}
.courses_button
{
	width: 210px;
	height: 46px;
	border-radius: 3px;
	background: #14bdee;
	text-align: center;
	margin: 0 auto;
	margin-top: 41px;
	box-shadow: 0px 5px 40px rgba(29,34,47,0.15);
}
.courses_button:hover
{
	box-shadow: 0px 5px 40px rgba(29,34,47,0.45);
}
.courses_button a
{
	display: block;
	font-size: 14px;
	font-weight: 500;
	text-transform: uppercase;
	line-height: 46px;
	color: #FFFFFF;
}
.section_subtitle p {
	color: #315670;
}
/*********************************
9. Counter
*********************************/
.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.3); /* Adjust the alpha value (0.5) for the darkness */
  }

.navbar-before-scroll .nav-link,
.navbar-before-scroll .navbar-toggler-icon {
  color: #fff;
}

/* Color of the links AFTER scroll */
.navbar-after-scroll .nav-link,
.navbar-after-scroll .navbar-toggler-icon {
  color: yellow;
}

/* Color of the navbar AFTER scroll */
.navbar-after-scroll {
  background-color: #fff;
}

/* Transition after scrolling */
.navbar-after-scroll {
  transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
}

/* Transition to the initial state */
.navbar-before-scroll {
  transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
}

/* An optional height of the navbar AFTER scroll */
.navbar.navbar-before-scroll.navbar-after-scroll {
  padding-top: 5px;
  padding-bottom: 5px;
}

/* Navbar on mobile */
@media (max-width: 991.98px) {
  #main-navbar {
    background-color: #fff;
  }

  .nav-link,
  .navbar-toggler-icon {
    color: #4f4f4f !important;
  }
}
.counter
{
	width: 100%;
	background: #FFFFFF;
	z-index: 2;
}
.counter_background
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
}
.counter_content
{
	padding-top: 119px;
	padding-bottom: 125px;
}
.counter_title
{
	font-family: 'Roboto Slab', serif;
	color: #FFFFFF;
	font-weight: 700;
}
.counter_text
{
	margin-top: 19px;
}
.counter_text p
{
	color: #FFFFFF;
}
.milestones
{
	margin-top: 39px;
}
.milestone
{
	text-align: center;
}
.milestone:not(:last-child)::after
{
	display: block;
	position: absolute;
	top: 0;
	right: -45px;
	width: 1px;
	height: 70px;
	background: rgba(255,255,255,0.2);
	content: '';
	-webkit-transform: rotate(30deg);
	-moz-transform: rotate(30deg);
	-ms-transform: rotate(30deg);
	-o-transform: rotate(30deg);
	transform: rotate(30deg);

}
.milestone_counter
{
	font-size: 42px;
	font-weight: 700;
	line-height: 0.75;
	color: #14bdee;
}
.milestone_text
{
	font-size: 16px;
	font-weight: 400;
	color: #FFFFFF;
	text-transform: uppercase;
	margin-top: 14px;
}
.counter_form
{
	position: absolute;
	top: 0;
	right: 30px;
	width: 380px;
	height: 100%;
	background: #FFFFFF;
	padding-left: 40px;
	padding-right: 40px;
	box-shadow: 0px 5px 40px rgba(29,34,47,0.15);
}
.counter_form_content
{
	display: block;
	position: relative;
	width: 100%;
	height: 100%;
}
.counter_form_title
{
	font-family: 'Roboto Slab', serif;
	font-size: 24px;
	font-weight: 700;
	color: #384158;
	text-transform: uppercase;
	line-height: 0.75;
	margin-bottom: 41px;
}
.counter_input
{
	width: 100%;
	height: 46px;
	border: solid 1px #e5e5e5;
	border-radius: 3px;
	padding-left: 20px;
	outline: none;
	color: #384158;
	font-size: 14px;
}
.counter_input:not(:last-child)
{
	margin-bottom: 10px;
}
.counter_text_input
{
	height: 90px;
	padding-top: 10px;
}
.counter_options
{
	position: relative;
	-webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    -webkit-box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
	-webkit-user-select: none;
	/* background-image: url(../images/down.png); */
	background-position: center right;
	background-repeat: no-repeat;
}
.counter_input::-webkit-input-placeholder,
.counter_text_input::-webkit-input-placeholder
{
	font-size: 14px !important;
	font-weight: 400 !important;
	color: #b5b8be !important;
}
.counter_input:-moz-placeholder,
.counter_text_input:-moz-placeholder
{
	font-size: 14px !important;
	font-weight: 400 !important;
	color: #b5b8be !important;
}
.counter_input::-moz-placeholder,
.counter_text_input::-moz-placeholder
{
	font-size: 14px !important;
	font-weight: 400 !important;
	color: #b5b8be !important;
} 
.counter_input:-ms-input-placeholder,
.counter_text_input:-ms-input-placeholder
{ 
	font-size: 14px !important;
	font-weight: 400 !important;
	color: #b5b8be !important;
}
.counter_input::input-placeholder,
.counter_text_input::input-placeholder
{
	font-size: 14px !important;
	font-weight: 400 !important;
	color: #b5b8be !important;
}
.counter_form_button
{
	width: 100%;
	height: 46px;
	color: #FFFFFF;
	font-size: 14px;
	font-weight: 500;
	text-transform: uppercase;
	border: none;
	outline: none;
	background: #14bdee;
	cursor: pointer;
	margin-top: 30px;
	box-shadow: 0px 5px 40px rgba(29,34,47,0.15);
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.counter_form_button:hover
{
	box-shadow: 0px 5px 40px rgba(29,34,47,0.45);
}

/*********************************
10. Events
*********************************/

.events
{
	width: 100%;
	padding-top: 93px;
	padding-bottom: 70px;
	background: #FFFFFF;
}
.events_row
{
	margin-top: 47px;
}
.event_left
{
	padding-right: 20px;
}
.event_mid
{
	padding-left: 10px;
	padding-right: 10px;
}
.event_right
{
	padding-left: 20px;
}
.event_image
{
	width: 100%;
	border-radius: 5px;
	overflow: hidden;
}
.event_image img
{
	max-width: 100%;
}
.event_body
{
	padding-top: 30px;
	padding-bottom: 30px;
}
.event_date
{
	box-shadow: 0px 1px 10px rgba(29, 34, 47, 0.1);
}
.event_date > div
{
	width: 60px;
	height: 60px;
}
.event_day
{
	font-family: 'Roboto Slab', serif;
	font-size: 24px;
	color: #14bdee;
	font-weight: 700;
	line-height: 0.75;
}
.event_month
{
	font-family: 'Roboto Slab', serif;
	font-size: 14px;
	color: #14bdee;
	font-weight: 400;
	line-height: 0.75;
	margin-top: 9px;
}
.event:hover .event_date > div
{
	background: #14bdee;
}
.event:hover .event_day,
.event:hover .event_month
{
	color: #FFFFFF;
}
.event_content
{
	padding-left: 20px;
}
.event_title a
{
	font-family: 'Roboto Slab', serif;
	font-size: 20px;
	font-weight: 700;
	line-height: 1.4;
	color: #384158;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.event_title a:hover
{
	color: #14bdee;
}
.event_info_container
{
	margin-top: 11px;
}
.event_info
{
	display: inline-block;
	color: #b5b8be;
	font-size: 14px;
}
.event_info:not(:last-child)
{
	margin-right: 17px;
}
.event_info span
{
	margin-left: 4px;
}
.event_text
{
	margin-top: 12px;
}
.caption {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5); /* Ubah angka terakhir (0.5) sesuai dengan tingkat transparansi yang Anda inginkan */
  }
/*********************************
11. Team
*********************************/

.team
{
	width: 100%;
	padding-top: 40px;
	padding-bottom: 60px;
}
.team_background
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.team_row
{
	margin-top: 43px;
}
.team_col
{
	margin-bottom: 40px;
}
.team_item
{
	width: 100%;
}
.team_image
{
	width: 300px;
	height: 220px;
	border-radius: 6px;
	overflow: hidden;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: -86px;
	z-index: 5;
	text-align: center;
}
.team_image img
{
	max-width: 90%;
	border-radius: 6px;

}
.team_body
{
	width: 100%;
	align-items: center;
	padding-top: 108px;
	padding-bottom: 24px;
	background: #ffd31a;
	border-radius: 6px;
	box-shadow: 0px 1px 10px rgba(29,34,47,0.1);
	text-align: center;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.team_item:hover .team_body
{
	box-shadow: 0px 5px 40px rgba(29,34,47,0.15);
}
.team_title a
{
	font-family: 'Roboto Slab', serif;
	font-size: 20px;
	font-weight: 700;
	color: #384158;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.team_title a:hover
{
	color: #0b0c0d;
}
.team_subtitle
{
	font-size: 14px;
	font-weight: 400;
	color: #1b1818;
	margin-top: 6px;
}
.social_list
{
	margin-top: 16px;
}
.social_list ul li
{
	display: inline-block;
}
.social_list ul li:not(:last-child)
{
	margin-right: 10px;
}
.social_list ul li a i
{
	font-size: 18px;
	color: #76777a;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.social_list ul li:hover a i
{
	color: #14bdee;
}
.team_col .team_item
{
	padding-left: 6px;
	padding-right: 6px;
}
.team_col:first-child .team_item
{
	padding-left: 0;
	padding-right: 6px;
}
.team_col:nth-child(4n) .team_item
{
	padding-left: 6px;
	padding-right: 0;
}

/*********************************
12. News
*********************************/

.news
{
	width: 100%;
	padding-top: 93px;
	padding-bottom: 100px;
	background: #FFFFFF;
}
.news_row
{
	margin-top: 55px;
}
.news_post_large_container
{
	padding-right: 30px;
}
.news_post_large
{
	width: 100%;
}
.news_post_image
{
	width: 100%;
	border-radius: 6px;
	overflow: hidden;
}
.news_post_image img
{
	max-width: 100%;
}
.news_post_large_title
{
	margin-top: 23px;
}
.news_post_large_title a
{
	font-family: 'Roboto Slab', serif;
	font-size: 20px;
	font-weight: 700;
	color: #384158;
	line-height: 1.4;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.news_post_large_title a:hover
{
	color: #14bdee;
}
.news_post_meta
{
	margin-top: 11px;
}
.news_post_meta ul li
{
	display: inline-block;
	position: relative;
}
.news_post_meta ul li:not(:last-of-type)::after
{
	display: inline-block;
	position: relative;
	content: '|';
	margin-left: 14px;
	margin-right: 11px;
}
.news_post_meta ul li a
{
	font-size: 14px;
	font-weight: 400;
	color: #b5b8be;
	text-transform: uppercase;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.news_post_meta ul li a:hover
{
	color: #14bdee;
}
.news_post_text
{
	margin-top: 13px;
}
.news_post_link
{
	margin-top: 19px;
}
.news_post_link a
{
	font-size: 14px;
	font-weight: 400;
	text-transform: uppercase;
	color: #384158;
	text-decoration: underline;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.news_post_link a:hover
{
	color: #14bdee;
}
.news_posts_small
{
	margin-top: -6px;
}
.news_post_small:not(:last-child)
{
	padding-bottom: 31px;
	border-bottom: solid 1px #e5e5e5;
}
.news_post_small:not(:first-child)
{
	padding-top: 29px;
}
.news_post_small_title a
{
	font-family: 'Roboto Slab', serif;
	font-size: 18px;
	font-weight: 700;
	line-height: 1.55;
	color: #384158;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.news_post_small_title a:hover
{
	color: #14bdee;
}

/*********************************
13. Newsletter
*********************************/

.newsletter
{
	width: 100%;
}
.newsletter_background
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.newsletter_container
{
	width: 100%;
	height: 120px;
}
.newsletter_title
{
	font-family: 'Roboto Slab', serif;
	font-size: 20px;
	font-weight: 700;
	text-transform: uppercase;
	color: #FFFFFF;
}
.newsletter_subtitle
{
	font-size: 14px;
	font-weight: 400;
	color: #FFFFFF;
	margin-top: 6px;
}
.newsletter_form_container
{
	width: 450px;
	padding-left: 20px;
}
.newsletter_form
{
	display: block;
	position: relative;
	width: 100%;
}
.newsletter_input
{
	width: calc(100% - 136px);
	height: 40px;
	background: rgba(255,255,255,0.4);
	border: none;
	outline: none;
	padding-left: 20px;
	color: #FFFFFF;
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
}
.newsletter_input::-webkit-input-placeholder
{
	font-size: 14px !important;
	font-weight: 400 !important;
	color: #FFFFFF !important;
}
.newsletter_input:-moz-placeholder
{
	font-size: 14px !important;
	font-weight: 400 !important;
	color: #FFFFFF !important;
}
.newsletter_input::-moz-placeholder
{
	font-size: 14px !important;
	font-weight: 400 !important;
	color: #FFFFFF !important;
} 
.newsletter_input:-ms-input-placeholder
{ 
	font-size: 14px !important;
	font-weight: 400 !important;
	color: #FFFFFF !important;
}
.newsletter_input::input-placeholder
{
	font-size: 14px !important;
	font-weight: 400 !important;
	color: #FFFFFF !important;
}
.newsletter_button
{
	width: 136px;
	height: 40px;
	background: #FFFFFF;
	border-radius: 3px;
	border: none;
	outline: none;
	cursor: pointer;
	font-size: 14px;
	text-transform: uppercase;
	color: #14bdee;
	font-weight: 500;
	letter-spacing: 0.1em;
}

/*********************************
14. Footer
*********************************/

.footer
{
	display: block;
	position: relative;
	width: 100%;
	background: #1e2434;
	padding-top: 94px;
}
.footer_background
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
}
.footer_content
{
	padding-bottom: 53px;
}
.footer_logo_text
{
	font-family: 'Roboto Slab', serif;
	font-size: 36px;
	font-weight: 700;
	line-height: 0.75;
	color: #FFFFFF;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.footer_logo_text span
{
	color: #14bdee;
}
.footer_title
{
	font-family: 'Roboto Slab', serif;
	font-size: 18px;
	font-weight: 700;
	color: #FFFFFF;
	line-height: 0.75;
}
.footer_logo_container
{
	margin-top: -14px;
}
.footer_about_text
{
	margin-top: 31px;
}
.footer_about_text p
{
	color: #FFFFFF;
}
.footer_social
{
	margin-top: 23px;
}
.footer_social ul li
{
	display: inline-block;
	width: 45px;
	height: 45px;
	background: #4b505d;
	border-radius: 50%;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.footer_social ul li:not(:last-child)
{
	margin-right: 6px;
}
.footer_social ul li a
{
	display: block;
	position: relative;
	text-align: center;
}
.footer_social ul li a i
{
	color: #FFFFFF;
	line-height: 45px;
}
.footer_social ul li:hover
{
	background: #14bdee;
}
.footer_contact
{
	padding-left: 36px;
}
.footer_contact_info
{
	margin-top: 33px;
}
.footer_contact_info ul li
{
	font-size: 14px;
	font-weight: 400;
	color: #b5b8be;
}
.footer_contact_info ul li:not(:last-child)
{
	margin-bottom: 15px;
}
.footer_links
{
	padding-left: 80px;
}
.footer_links_container ul
{
	columns: 2;
	-webkit-columns: 2;
	-moz-columns: 2;
}
.footer_links_container
{
	margin-top: 33px;
}
.footer_links_container ul li a
{
	font-size: 14px;
	color: #b5b8be;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.footer_links_container ul li:not(:last-child)
{
	margin-bottom: 15px;
}
.footer_links_container ul li a:hover
{
	color: #14bdee;
}
.footer_mobile
{
	display: inline-block;
	float: right;
}
.footer_mobile_content
{
	padding-top: 35px;
}
.footer_image:not(:last-child)
{
	margin-bottom: 10px;
}
.copyright
{
	height: 54px;
	border-top: solid 1px #353a49;
}
.cr_list li
{
	display: inline-block;
}
.copyright div
{
	font-size: 14px;
	color: #b5b8be;
}
.cr_text a
{
	color: #FFFFFF;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.cr_text a:hover
{
	color: #14bdee;
}
.cr_list li:not(:last-child)
{
	margin-right: 50px;
}
.cr_list li a
{
	font-size: 14px;
	color: #b5b8be;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.cr_list li a:hover
{
	color: #14bdee;
}