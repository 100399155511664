main {
    /* padding-left: 240px; */
  }
  
  .card.map-card .living-coral {
    background-color: #fa7268;
  }
  
  .card.map-card .living-coral-text {
    color: #fa7268;
  }
  
  .card.map-card .table th {
    width: 2rem;
  }
  
  .card.map-card .pt-3-5 {
    padding-top: 1.25rem;
  }
  
  .card.map-card .card-body {
    position: absolute;
    width: 100%;
    height: 80%;
    top: 20%;
    overflow: hidden;
    background-color: transparent;
    -webkit-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
    -webkit-border-radius: 0 !important;
    border-radius: 0 !important;
  }
  
  .card.map-card .card-body.closed {
    top: 100%;
    height: 7.5rem;
    margin-top: -7.5rem;
  }
  
  .card.map-card .map-container {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }
  
  .card.map-card .map-container iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
  
  .card.map-card .button .btn-floating {
    margin-top: -1.5rem;
  }
  
  blockquote {
    margin-bottom: 0px !important;
  }
  .map{
    
  }