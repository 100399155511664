@charset "utf-8";
/* CSS Document */

/******************************

[Table of Contents]

1. 1600px
2. 1440px
3. 1280px
4. 1199px
5. 1024px
6. 991px
7. 959px
8. 880px
9. 768px
10. 767px
11. 539px
12. 479px
13. 400px

******************************/

/************
1. 1600px
************/

@media only screen and (max-width: 1600px)
{
	
}

/************
2. 1440px
************/

@media only screen and (max-width: 1440px)
{
	
}

/************
3. 1380px
************/

@media only screen and (max-width: 1380px)
{
	
}

/************
3. 1280px
************/

@media only screen and (max-width: 1280px)
{
	
}

/************
4. 1199px
************/

@media only screen and (max-width: 1199px)
{
	.home_slider_prev
	{
		left: 25px;
	}
	.home_slider_next
	{
		right: 25px;
	}
	.course_info:first-child
	{
		margin-right: 12px;
	}
	.milestone:not(:last-child)::after
	{
		display: none;
	}
	.team_image
	{
		width: 300px;
		height: 280px;
	}
	
}

/************
4. 1100px
************/

@media only screen and (max-width: 1100px)
{
	
}

/************
5. 1024px
************/

@media only screen and (max-width: 1024px)
{
	
}

/************
6. 991px
************/

@media only screen and (max-width: 991px)
{	
	.navbar-toggler {
		background-color: #FFD31A;
		/* Add more styles here if needed */
	  }
	  
	  /* Optional: Change the color of the toggle icon */
	  .navbar-toggler-icon {
		color: #333; /* Change the color of the toggle button icon */
	  }	  
	/* responsive.css */
	.main_nav_toggle {
		display: none;
		cursor: pointer;
	}
	
	.main_nav_toggle .navbar-toggler-icon {
		background-color: yellow;
		/* Add more styles here if needed */
	}
	.main_nav,
	.search_button
	{
		display: none;
	}
	.top_bar_contact_list li i,
	.top_bar_contact_list li > div,
	.login_button a
	{
		font-size: 12px;
	}
	.login_button
	{
		width: 140px;
	}
	.hamburger
	{
		display: inline-block;
		margin-left: 33px;
	}
	.home_slider_form_container
	{
		width: 100%;
	}
	.home_search_button
	{
		margin-top: 15px;
	}
	.feature_col:not(:last-child)
	{
		margin-bottom: 30px;
	}
	.course_col:not(:last-child)
	{
		margin-bottom: 40px;
	}
	.counter_form
	{
	    position: relative;
	    top: 0;
	    right: auto;
	    width: 100%;
	    height: auto;
	    padding-left: 40px;
	    padding-right: 40px;
	    padding-top: 60px;
	    padding-bottom: 60px;
	}
	.counter_content
	{
		padding-top: 100px;
		padding-bottom: 68px;
	}
	.counter
	{
		padding-bottom: 100px;
	}
	.event_col:not(:last-child)
	{
		margin-bottom: 20px;
	}
	.team_col .team_item
	{
		padding-left: 0px;
		padding-right: 0px;
	}
	.team_col:first-child .team_item
	{
		padding-left: 0;
		padding-right: 0px;
	}
	.team_col:nth-child(4n) .team_item
	{
		padding-left: 0px;
		padding-right: 0;
	}
	.team_col:nth-child(odd) .team_item
	{
		padding-right: 6px;
	}
	.team_col:nth-child(even) .team_item
	{
		padding-left: 6px;
	}
	.news_col:not(:last-child)
	{
		margin-bottom: 80px;
	}
	.newsletter_container
	{
		height: auto;
		padding-top: 40px;
		padding-bottom: 40px;
	}
	.newsletter_form_container
	{
		margin-top: 19px;
	}
	.footer_col:not(:last-child)
	{
		margin-bottom: 60px;
	}
	.footer_contact,
	.footer_links
	{
		padding-left: 0;
	}
	.footer_links ul
	{
		columns: 1;
		-webkit-columns: 1;
		-moz-columns: 1;
	}
	.footer_mobile
	{
		float: none;
	}
	.footer_logo_container
	{
		margin-top: 0;
	}
	.copyright
	{
	    height: auto;
	    padding-top: 30px;
	    padding-bottom: 30px;
	}
	.cr_list
	{
		margin-top: 30px;
	}
	.cr_list li:not(:last-child)
	{
		margin-right: 20px;
	}
	.copyright div,
	.cr_list li a
	{
		font-size: 13px;
	}
	
}

/************
7. 959px
************/

@media only screen and (max-width: 959px)
{
	
	
}

/************
8. 880px
************/

@media only screen and (max-width: 880px)
{
	
}

/************
9. 768px
************/

@media only screen and (max-width: 768px)
{
	.responsive-video {
		width: 100%; /* Lebar video mengisi lebar kontainer */
		height: auto; /* Tinggi video menyesuaikan agar tetap proporsional */
	  }
}

/************
10. 767px
************/
.main_nav_toggle {
	display: none;
	cursor: pointer;
	background-color: yellow;
  }
  
  .burger-icon {
	width: 30px;
	height: 3px;
	background-color: #544fef;
	margin: 6px 0;
	transition: 0.4s;
  }
@media only screen and (max-width: 767px)
{
	.main_nav {
		display: none;
		flex-direction: column;
		position: absolute;
		top: 50px; /* Adjust this value to position the menu below the header */
		left: 0;
		background-color: #f9f9f9;
		width: 100%;
		z-index: 1;
	  }
	
	  .main_nav.active {
		display: flex;
	  }
	
	  .main_nav li {
		padding: 10px;
	  }
	
	  .main_nav li a {
		color: #333;
		text-decoration: none;
	  }
	
	  .main_nav_toggle {
		display: block;
		margin-right: 20px;
	  }
	
	  .header_content.menu-open {
		position: relative;
	  }
	.top_bar
	{
		display: none;
	}
	.home_slider_nav
	{
		display: none;
	}
	.home_slider_title
	{
		font-size: 48px;
	}
	.home_search_form div
	{
		width: 100%;
	}
	.milestone:not(:last-child)
	{
		margin-bottom: 50px;
	}
	.team_col .team_item
	{
		padding-left: 0px;
		padding-right: 0px;
	}
	.team_col:first-child .team_item
	{
		padding-left: 0;
		padding-right: 0px;
	}
	.team_col:nth-child(4n) .team_item
	{
		padding-left: 0px;
		padding-right: 0;
	}
	.team_col:nth-child(odd) .team_item
	{
		padding-right: 0px;
	}
	.team_col:nth-child(even) .team_item
	{
		padding-left: 0px;
	}
	
}

/************
11. 575px
************/

@media only screen and (max-width: 575px)
{
	h2
	{
		font-size: 24px;
	}
	p
	{
		font-size: 13px;
	}
	.menu
	{
		width: 100%;
		right: -100%;
	}
	.home_search_input::-webkit-input-placeholder
	{
		font-size: 12px !important;
	}
	.home_search_input:-moz-placeholder
	{
		font-size: 12px !important;
	}
	.home_search_input::-moz-placeholder
	{
		font-size: 12px !important;
	} 
	.home_search_input:-ms-input-placeholder
	{ 
		font-size: 12px !important;
	}
	.home_search_input::input-placeholder
	{
		font-size: 12px !important;
	}
	.dropdown_item_select,
	.home_search_button
	{
		font-size: 12px;
	}
	.home_search_button
	{
		width: 100px;
		height: 40px;
	}
	.newsletter_form_container
	{
		width: 100%;
		padding-left: 0;
	}
}

/************
11. 539px
************/

@media only screen and (max-width: 539px)
{
	
}

/************
12. 480px
************/

@media only screen and (max-width: 480px)
{
	
}

/************
13. 479px
************/

@media only screen and (max-width: 479px)
{
	
}

/************
14. 400px
************/

@media only screen and (max-width: 400px)
{
	
}

/* Add this CSS to your responsive.css file or equivalent */

/* Styles for burger menu */


  